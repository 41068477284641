const pathAfterLogin = {
  setPath: (pathToSave, isAuthenticated) => {
    const notSavePaths = ['/login', '/', '/mfa']
    const shouldSave = notSavePaths.every(path => path !== pathToSave)
    if(!isAuthenticated && shouldSave){
      localStorage.setItem('pathAfterLogin', pathToSave);
    }
  },
  getPath: (ProfileService, getToken) => {
    const path = localStorage.getItem('pathAfterLogin');
    const token = ProfileService.parseJWT(getToken())
    const localSuccessChangePassword = localStorage.getItem('SUCCESS_CHANGE_PASSWORD')
    const needChangePassword = token.changePassword && (!localSuccessChangePassword || parseInt(localSuccessChangePassword) === 0);
    return needChangePassword 
      ? '/reset' 
      :path || '/home';
  },
  getPathWithoutToken: () => {
    return localStorage.getItem('pathAfterLogin') || '';
  },
  removePath: () =>{
    localStorage.removeItem('pathAfterLogin');
  }
}

export default pathAfterLogin;