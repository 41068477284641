import ApiService from "services/ApiService";
export const TeamService = {
  async getPasswordPolicy(teamId) {
    const { data } = await ApiService.get("/api/v1/team/passwordPolicy/"+teamId);
    return data
  },

  async getVisualIdentity() {
    const { data } = await ApiService.get("/admin/api/v1/visualidentity");
    return data.data
  },

  async getLevels() {
    const { data } = await ApiService.get('/api/v1/team/levels');
    return data.data
  },

  async getAvatars() {
    const { data } = await ApiService.get("/admin/api/v1/avatar")
    return data.data
  },

  async getPowerApps() {
    const {data} = await ApiService.get("/admin/api/v1/powerapps?limit=200")
    return data.data
  },

  async verifyPassword(password){
    const data = await ApiService.post('/api/v1/team/verifyPassword', { password: password })
    return data 
  },

  async getNpsInfo() {
    const { data } = await ApiService.get("/api/v1/nps");
    return data.data[0]
  },
}
