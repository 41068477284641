import ApiService from "services/ApiService";

export const UploadService = {
  async getSignedUrl(file) {
    const {name: filename, type: mimetype} = file
    const moduleName = 'user'
    const { data } = await ApiService.get(`/admin/api/v1/signed-url/${moduleName}`, {params: { filename, mimetype }})
    return data
  }
}
