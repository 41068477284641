import { lazy } from 'react'

const LoginScreen = lazy(() => import('../screen/Login'))
const ForgotScreen = lazy(() => import('../screen/ForgotPassword'))
const FirstAccess = lazy(() => import('../screen/FirstAccess'))
const SelectMethod = lazy(() => import('../screen/SelectMethod'))
const SignOnContainer   = lazy(() => import("../screen/SignOnContainer"))
const MFAScreen = lazy(() => import('../screen/MFA'))

const dashboard = [
  {
    path: '/login',
    component: LoginScreen,
    exact: true
  },{
    path: '/forgot',
    component: ForgotScreen,
    exact: true
  },{
    path: '/first-access',
    component: FirstAccess,
    exact: true
  },{
    path: '/first-access/select-method',
    component: SelectMethod,
    exact: true
  },{
    path: "/sso",
    component: SignOnContainer,
    exact: false
  },{
    path: '/mfa',
    component: MFAScreen,
    exact: true
  },
]

export default dashboard
