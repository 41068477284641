import ApiService from "services/ApiService";

export const CommentService = {
  async getCommentsAsync(postId, page) {
    const { data } = await ApiService.get( "/api/v1/feed/comments/"+postId+"/"+page);
    return data.data;
  },

  async saveCommentAsync(data) {
    const url = "/api/v1/feed/comments/save";
    return  await ApiService.post(url,data);
  },

  async getRepliesAsync({postId, commentId}) {
    const { data } = await ApiService.get( `/api/v1/feed/comments/replies/${commentId}/${postId}?limit=50`);
    return data.data;

  }
}
